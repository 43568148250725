.forgotPassContainer {
  height: 100vh;
}

.formContainer {
  padding-top: 70px;
}

.errorText {
  color: crimson;
  margin: 10px 0px 0px 0px;
  font-weight: bold;
}

.form {
  display: flex;
  flex-direction: column;
  width: 25vw;
}

.tabContainer {
  display: flex;
  justify-content: center;
  align-items: center;
  font-size: 1.2vw;
  font-weight: bold;
}

.tabMenu {
  display: flex;
  justify-content: center;
  align-items: center;
  font-size: 28px;
  font-weight: bold;
  width: 100%;
  color: #fff;
}
.mainContainer {
  display: flex;
  flex-direction: column;
  gap: 4rem;
}
.authText {
  font-size: 20px;
  margin-bottom: -9px;
}

.button {
  margin-top: 15px;
  width: 25vw;
  background-color: #ea3a1b !important;
}
.submitbtn {
  margin-top: -12px !important;
  width: 18vw;
  background-color: #ea3a1b !important;
  border-radius: 0px !important;
  border-color: #ea3a1b !important;
  padding: 7px !important;
}
.backbtn {
  margin-top: -5px !important;
  width: 18vw;
  border-radius: 0px !important;
  padding: 7px !important;
}
.otpContainer {
  display: flex;
  flex-direction: column;
  font-weight: bold;
  gap: 2rem;
  margin-bottom: 40px;
}
.otpBox {
  display: flex;
  justify-content: space-around;
  padding-left: 10%;
  padding-right: 10%;
  margin-top: 2rem;
}
.otpField {
  padding: 15px;
  height: 50px;
  width: 45px;
  text-align: center;
  border-radius: 5px;
  background-color: var(--white);
  color: var(--back);
  font-weight: bold;
  font-size: 18px;
}
.apiValidationError {
  width: 24vw;
  background-color: #ffa7a7;
  color: black;
  min-height: 3rem;
  border-radius: 0.4rem;
  display: flex;
  justify-content: center;
  align-items: center;
  margin-top: 1rem;
  padding: 5px;
}

@media (max-width: 1000px) {
  .form {
    width: 35vw;
  }
}

@media (max-width: 480px) {
  .form {
    width: 80vw;
    /* background-color:aqua; */
  }
  .tabContainer {
    font-size: 3vw;
  }
  .guestUserNotifierText {
    font-size: 3vw;
  }
}
