.cardWrapper {
  display: flex;
  flex-direction: column;
  align-items: flex-end;
  overflow: hidden;
  position: relative;
  aspect-ratio: 0.73;
  width: 205px;
  padding: 8px 8px 0 0px;
}

.cardImage {
  position: absolute;
  inset: 0;
  height: 100%;
  width: 100%;
  object-fit: cover;
  object-position: center;
}

.deleteButton {
  position: relative;
  justify-content: center;
  align-items: center;
  border-radius: 64.865px;
  background-color: #ea3a1b;
  display: flex;
  width: 25px;
  height: 25px;
  padding: 0 4px;
  cursor: pointer;
}

.deleteIcon {
  aspect-ratio: 1;
  object-fit: auto;
  object-position: center;
  width: 100%;
}

.deleteButton:hover {
  background-color: gray;
}

.skeleton {
  position: absolute;
  inset: 0;
  height: 100%;
  width: 100%;
  background-color: #2a2a2a;
  animation: pulse 1.5s infinite;
}

@keyframes pulse {
  0% {
    opacity: 0.6;
  }
  50% {
    opacity: 0.8;
  }
  100% {
    opacity: 0.6;
  }
}
