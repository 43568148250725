@font-face {
  font-family: "poppins-regular";
  src: url("../fonts/NotoSans_Condensed-Regular.ttf") format("truetype"),
    url("https://fonts.googleapis.com/css2?family=Noto+Sans:wght@400;700&display=swap") format("truetype"),
    local("poppins"), local("poppins-regular");
}
@font-face {
  font-family: "notosans-bold";
  src: url("../fonts/NotoSans_Condensed-Bold.ttf") format("truetype"),
    url("https://fonts.googleapis.com/css2?family=Noto+Sans&display=swap") format("truetype"), local("poppins"),
    local("notosans-bold");
}

@font-face {
  font-family: "Roboto";
  src: local("roboto"), local("roboto-regular"), url("../fonts/Roboto-Regular.ttf") format("truetype"),
    url("https://fonts.googleapis.com/css2?family=Roboto&display=swap");
}

* {
  font-family: "poppins-regular", sans-serif;
  font-style: normal;
}

*::-webkit-scrollbar {
  width: 5px;
}

*::-webkit-scrollbar-thumb {
  background: #252525;
  opacity: 0.7;
  border-radius: 3px;
}

*::-webkit-scrollbar-corner {
  background: none;
}

*:focus {
  outline: none;
}

/* toast message style */
.Toastify__toast {
  border-radius: 0.3vw;
  width: 100%;
  padding: 0.5vw 0;
}

.Toastify__toast-body {
  padding: 0.5vw;
  font-size: 1vw;
}

.Toastify__toast-icon {
  width: 1.5vw;
  margin: 0.5vw;
}

.Toastify__close-button > svg {
  width: 1.5vw;
  height: 1vw;
}

.Toastify__toast-container--bottom-right {
  width: 20vw;
}

@media (max-width: 480px) {
  .Toastify__toast-body {
    padding: 0.5vw;
    font-size: 4vw;
  }
}

.primary-color {
  color: #ea3a1b;
}

.d-flex-center {
  display: flex !important;
  justify-content: center;
  align-items: center;
}
