.storeContainer {
  position: fixed;
  bottom: 0;
  left: 0;
  margin: 2rem 0;
  width: 100%;
  /* height: 21vh; */
  margin-top: auto;
  margin-bottom: auto;
  display: flex;
  justify-content: space-between;
  background-color: #242424;
  padding: 1.5vw;
  background-size: 100% 40%;
  background-position: bottom;
  background-repeat: no-repeat;
}

.footerImageContainer {
  display: flex;
  justify-content: center;
}

.footerImage {
  margin-top: -10px;
  height: 3.5vw;
  width: 10vw;
}

.storeContainer1 {
  margin: 2rem 0;
  width: 100%;
  /* height: 21vh; */
  margin-top: auto;
  margin-bottom: auto;
  display: flex;
  justify-content: space-between;
  background-color: #242424;
  padding: 1.5vw;
  background-size: 100% 40%;
  background-position: bottom;
  background-repeat: no-repeat;
}
.footerImage1 {
  height: 2.5vw;
  width: 7.8vw;
}
.storeSubContainer1 {
  display: flex;
  justify-content: space-between;
  width: 18vw;
}
.textContainer {
  width: 25vw;
  display: flex;
  justify-content: space-between;
  color: #fff;
}
.rightSide {
  padding-right: 2vw;
  display: flex;
  gap: 30px;
  flex-direction: column;
  justify-content: right;
  align-items: flex-end;
  text-align: end;
  margin-bottom: 20px;
}
.leftSide {
  display: flex;
  flex-direction: column;
  gap: 80px;
}
.copyRight {
  font-size: 11px;
}
.links {
  color: #ffff;
}

@media (max-width: 480px) {
  .footerImage {
    height: 8vw;
    width: 22vw;
  }
  .storeSubContainer {
    width: 50vw;
  }
}
