/* main container */
.modalContainer {
  padding: 20px 0;
}

/* modal header */
.modalHeader {
  font-size: 24px;
  font-weight: bold;
  text-align: center;
}

/* modal body */
.imgContianer {
  display: flex;
  justify-content: center;
  align-items: center;
}

.img {
  height: 350px;
  width: 350px;
}

.bodyTextContainer {
  font-size: 18px;
  text-align: center;
}

/* modal Footer */
.signUpBtn {
  display: block;
  margin: 40px auto;
  padding: 8px 40px;

  outline: none;
  border: none;
  border-radius: 20px;
  background-color: #289c5b;

  color: #fff;
  font-size: 16px;
  font-weight: bold;
}

.footerTextContainer {
  font-size: 16px;
  text-align: center;
}

.login {
  color: #fff;
  font-weight: bold;
  cursor: pointer;
}
