.contentSection {
  display: flex;
  /* margin-top: 50px; */
  width: 100%;
  flex-direction: column;
  padding: 0 70px;
}

.sectionTitle {
  color: #fff;
  font-size: 24px;
  margin-left: 20px;
}

.contentGrid {
  display: flex;
  flex-wrap: wrap;
  gap: 20px;
  justify-content: center;
  margin-top: 30px;
  padding-left: 20px;
}

.expandGrid {
  display: grid;
  padding-left: 0px;
  grid-template-columns: repeat(5, 1fr);
  gap: 20px;
  margin-top: 30px;
  column-gap: 5px;
  overflow: auto;
}

.arrowStyles {
  font-size: 25px;
  cursor: pointer;
}

.loadingWrapper {
  min-height: 70vh;
  width: 100%;
}

@media (max-width: 991px) {
  .sectionTitle {
    max-width: 100%;
  }

  .contentSection {
    max-width: 100%;
    margin-top: 40px;
    padding: 0 20px;
  }

  .expandGrid {
    grid-template-columns: repeat(3, 1fr);
  }
}
