.CardMetaContainer {
  /* background: linear-gradient(152.97deg, rgba(255, 255, 255, 0.2) 0%, rgba(255, 255, 255, 0) 100%); */
  /* backdrop-filter: blur(30px); */
  /* border-radius: 14px; */
  margin-bottom: 10px;
}

.watchlistButton {
  position: absolute;
  top: 2%;
  right: 2%;
  padding: 2px;
  height: 22px;
  width: 22px;
  border-radius: 100%;
  cursor: pointer;
  color: grey;
  font-size: 22px;
  display: flex;
  justify-content: center;
  align-items: center;
  background: #d9d9d9;
}

.watchlistShareButton {
  position: absolute;
  top: 2%;
  right: 17%;
  padding: 2px;
  height: 22px;
  width: 22px;
  border-radius: 100%;
  background: grey;
  cursor: pointer;
  font-size: 20px;
  display: flex;
  justify-content: center;
  align-items: center;
}

.watchListDetail {
  font-weight: 500;
  font-size: 14px;
}

.avatarSize {
  font-size: 2000px;
}

.watchlistHeading {
  color: white;
  font-size: 1.5rem;
  display: flex;
  align-items: center;
  margin: 0;
  margin-left: 10px;
}

.watchlistContainer {
  margin: 6px;
  margin-bottom: 0;
}

.watchListTitle {
  width: 200px;
  margin-bottom: 0px;
}

.wacthlistIconSize {
  font-size: 90px !important;
}

.subscribeButton {
  color: #289c5b;
  cursor: pointer;
  width: 100%;
  margin: 10px 0px 5px 0px;
  padding: 10px 15px;
  outline: none;
  border-radius: 50px;
  font-weight: bold;
  background: #3f3f3f;
  border: none;
  display: flex;
  align-items: center;
  font-size: 13px;
  justify-content: center;
}

.unsubscribeButton {
  color: #ea3a1b;
  cursor: pointer;
  margin: 10px 0px 5px 0px;
  width: 100%;
  padding: 10px 15px;
  outline: none;
  border-radius: 50px;
  font-weight: bold;
  background: #3f3f3f;
  border: none;
  display: flex;
  align-items: center;
  font-size: 13px;
  justify-content: center;
}
.shareButton {
  cursor: pointer;
  margin: 10px 0px 5px 0px;
  width: 100%;
  padding: 10px 15px;
  outline: none;
  border-radius: 50px;
  font-weight: bold;
  background: #3f3f3f;
  border: none;
  display: flex;
  align-items: center;
  font-size: 12px;
  justify-content: center;
}

.mediaButtonLeft {
  position: absolute;
  z-index: 1;
  top: 0px;
  bottom: 0px;
  left: -30px;

  display: flex;
  justify-content: center;
  align-items: center;

  width: 5vw;

  border: none;
  color: #fff;
  /* background: linear-gradient(to left, transparent, rgba(12, 17, 27, 0.7), rgba(12, 17, 27, 0.9)); */
  background-color: transparent;
  font-size: 30px;

  cursor: pointer;
}

.mediaButtonRight {
  position: absolute;
  z-index: 1;
  top: 0px;
  bottom: 0px;
  right: -30px;

  display: flex;
  justify-content: center;
  align-items: center;

  width: 5vw;

  border: none;
  color: #fff;
  /* background: linear-gradient(to right, transparent, rgba(12, 17, 27, 0.7), rgba(12, 17, 27, 0.9)); */
  font-size: 30px;

  cursor: pointer;
  background-color: transparent;
}

.visibilityBadge {
  position: absolute;
  top: 8px;
  justify-content: center;
  border-radius: 0 8px 8px 0;
  background-color: rgba(0, 0, 0, 0.53);
  display: flex;
  /* margin-bottom: 20px; */
  /* width: 87px; */
  flex-direction: column;
  padding: 3px 5px;
}

.badgeContent {
  display: flex;
  gap: 8px;
}

.badgeIcon {
  aspect-ratio: 1;
  object-fit: auto;
  object-position: center;
  width: 16px;
  margin: auto 0;
}

.newPlaylistButton {
  border-radius: 8px;
  border: 1px solid #ea3a1b;
  background-color: #ea3a1b;
  color: #fff;
  padding: 8px 20px;
  font-size: 15px;
}

.header {
  display: flex;
  width: 99%;
  justify-content: space-between;
  margin-bottom: 20px;
}

.moreButton {
  display: flex;
  align-items: center;
  gap: 4px;
  color: #ea3a1b;
  background: none;
  border: none;
  cursor: pointer;
  font-size: 18px;
  margin-left: 9px;
}
.moreIcon {
  width: 20px;
  height: 18px;
}

.leftHeadingSection {
  display: flex;
  align-items: baseline;
}
