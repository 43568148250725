.container {
  height: 95vh;
  width: 100%;
  text-align: center;
}
.container h2 {
  font-size: 2vw;
  font-weight: bold;
  margin-top: 2vw;
}
.imageContainer {
  height: 70%;
  display: flex;
  justify-content: center;
  align-items: center;
}
.unAuthorizedImage {
  max-width: 90%;
  max-height: 90%;
  object-fit: contain;
}

.buttonContainer {
  display: flex;
  justify-content: center;
  gap: 0.5vw;
}
.button {
  color: #fff;
  background: #ea3a1b;
  border: none;
  border-radius: 1.5vw;
  padding: 0.5vw 2vw;
  font-size: 1vw;
  font-weight: 700;
  margin-top: 0.5vw;
}
