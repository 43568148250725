.registerContainer {
  height: 100vh;
}

.formContainer {
  display: flex;
  justify-content: space-around;
}

.form {
  display: flex;
  flex-direction: column;
  width: 25vw;
  gap: 1rem;
}

.button {
  width: 14vw;
  border-radius: 4px !important;
}
.loginLeftContainer {
  background-color: #24272e;
  display: flex;
  flex-direction: column;
  gap: 2rem;
  align-items: center;
  width: 50%;
  padding: 20px;
  border-bottom-left-radius: 1.5rem;
  border-top-left-radius: 1.5rem;
}
.loginLogo {
  height: 40vh;
  width: 30vw;
  margin-top: 20px;
}
.loginLeftContainerText {
  width: 20vw;
  font-size: 20px;
  font-weight: bold;
  text-align: center;
}
.authText {
  font-size: 20px;
  margin-bottom: -12px;
}
.registerBtn {
  background-color: #ea3a1b !important;
  width: 25vw;
  border-radius: 4px !important;
}
.otpValidContainer {
  display: flex;
  flex-direction: column;
  gap: 7rem;
  text-align: center;
  margin-top: 5rem;
}
.otpContainerText {
  font-size: 1.6rem;
  width: 25vw;
}
.otpContainerHeading {
  font-size: 1.7rem;
  font-weight: bold;
}
.otpBoxContainer {
  display: flex;
  justify-content: space-around;
  padding-left: 7%;
  padding-right: 7%;
  margin-bottom: 9%;
  margin-top: 7%;
}
.otpField {
  padding: 15px;
  height: 50px;
  width: 45px;
  text-align: center;
  border-radius: 5px;
  background-color: var(--white);
  color: var(--back);
  font-weight: bold;
  font-size: 18px;
}
.btnContainer {
  display: flex;
  flex-direction: column;
}
.submitbutton {
  width: 14vw;
  background-color: #ea3a1b !important;
  border-radius: 4px !important;
}
.apiValidationError {
  width: 20vw;
  background-color: #ffa7a7;
  color: black;
  min-height: 3rem;
  border-radius: 0.4rem;
  display: flex;
  justify-content: center;
  align-items: center;
  margin-top: 2rem;
  padding: 5px;
  margin-left: 2.5rem;
}
@media (max-width: 1000px) {
  .form {
    width: 35vw;
  }
}

@media (max-width: 480px) {
  .form {
    width: 85vw;
    /* background-color:aqua; */
  }
  .forgotPassword {
    font-size: 2.5vw;
    padding-top: 1.5vw;
  }
  .guestUserNotifierText {
    font-size: 3vw;
  }
}

#signup > .ant-form-item {
  margin-bottom: 0;
}
