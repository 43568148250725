.mediaRowContainer {
  margin-bottom: 20px;
}

.mediaRowContainer .headingContainer {
  width: 100%;
}

.mediaRowContainer .seasonButtonContainer {
  display: flex;

  width: 100%;
  margin-bottom: 20px;

  overflow: scroll;
  scrollbar-width: none;
  -ms-overflow-style: none;
}

.mediaRowContainer .sliderContainer {
  width: 100%;
}

.mediaRowContainer .seasonButtonContainer::-webkit-scrollbar {
  display: none;
}

.mediaRowContainer .seasonButtonContainer .seasonButton {
  color: white;
  background-color: transparent;
  outline: none;
  border: none;

  padding: 10px;
  margin: 10px 30px 10px 0px;

  font-size: 16px;
  white-space: nowrap;

  cursor: pointer;
}

.mediaRowContainer .seasonButtonContainer .seasonButtonSelected {
  border-bottom: 4px solid #c65243;
  font-weight: bold;
}

/* Media Buttons */
.mediaButtonLeft {
  position: absolute;
  z-index: 1;
  top: 0px;
  bottom: 0px;
  left: -30px;

  display: flex;
  justify-content: center;
  align-items: center;

  width: 80px;

  border: none;
  border-top-right-radius: 8px;
  border-bottom-right-radius: 8px;

  color: #fff;
  background: hsla(0, 0%, 8%, 0.7);
  font-size: 30px;

  cursor: pointer;
}

.mediaButtonRight {
  position: absolute;
  z-index: 1;
  top: 0px;
  bottom: 0px;
  right: -30px;

  display: flex;
  justify-content: center;
  align-items: center;

  width: 80px;

  border: none;
  border-top-left-radius: 8px;
  border-bottom-left-radius: 8px;
  color: #fff;
  background: hsla(0, 0%, 8%, 0.7);
  font-size: 30px;

  cursor: pointer;
}

.mediaCardContainer {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
}
