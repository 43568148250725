.loginContainer {
  /* height: 100vh; */
  display: flex;
  justify-content: space-between;
}
.loginLeftContainer {
  background-color: #24272e;
  display: flex;
  flex-direction: column;
  gap: 2rem;
  align-items: center;
  width: 50%;
  padding: 20px;
  border-bottom-left-radius: 1.5rem;
  border-top-left-radius: 1.5rem;
}
.loginLogo {
  height: 40vh;
  width: 30vw;
  margin-top: 20px;
}
.loginLeftContainerText {
  width: 20vw;
  font-size: 20px;
  font-weight: bold;
  text-align: center;
}
.loginBtn {
  background-color: #ea3a1b !important;
  width: 25vw;
  border-radius: 4px !important;
}
.apiValidationError {
  width: 24vw;
  background-color: #ffa7a7;
  color: black;
  min-height: 3rem;
  border-radius: 0.4rem;
  display: flex;
  justify-content: center;
  align-items: center;
  margin-top: 1rem;
  padding: 5px;
}
.authText {
  font-size: 20px;
}

.formContainer {
  padding-top: 20px;
  margin-right: 100px;
}

.form {
  display: flex;
  flex-direction: column;
  width: 25vw;
  gap: 1rem;
}

.forgotPassword {
  color: #ea3a1b;
  font-size: 1vw;
  padding: 0 0.5rem;
  cursor: pointer;
  transition: color 0.3s;
  width: 9vw;
  margin-left: 68%;
}

.forgotPassword:hover {
  color: #ea3a1b;
}

.guestUserNotifierText {
  text-align: center;
  color: #ffffff;
  margin-top: 60px;
  font-size: 19px;
  opacity: 0.5;
}

@media (max-width: 1000px) {
  .form {
    width: 35vw;
  }
}

@media (max-width: 480px) {
  .form {
    width: 80vw;
    /* background-color:aqua; */
  }
  .forgotPassword {
    font-size: 2.5vw;
    padding-top: 1.5vw;
  }
  .guestUserNotifierText {
    font-size: 3vw;
  }
}
