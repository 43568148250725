.libraryPage {
  background-color: #1e1e1e;
  display: flex;
  flex-direction: column;
  min-height: 100vh;
}

.mainContent {
  display: flex;
  flex-direction: column;
  padding: 0 70px 80px;
}

.tabContainer {
  display: flex;
  justify-content: center;
  margin-top: 90px;
  gap: 16px;
}

.tabButton {
  font-family: Noto Sans, sans-serif;
  font-size: 16px;
  color: #c6c6c6;
  background: none;
  border: none;
  cursor: pointer;
  padding: 10px 20px;
  transition: color 0.3s ease;
}

.activeTab {
  color: #fff;
  background: #ea3a1b;
  border-radius: 8px;
}

.contentYouLike {
  color: #fff;
  margin-top: 40px;
}

@media (max-width: 991px) {
  .mainContent {
    padding: 0 20px 40px;
  }

  .tabContainer {
    margin-top: 40px;
  }
}

.loadingWrapper {
  min-height: 70vh;
}
