.recentActivityContainer {
  border-radius: 8px;
  /* border: 1px solid #626262; */
  display: flex;
  flex-grow: 1;
  flex-direction: column;
  height: 100%;
}

@media (max-width: 991px) {
  .recentActivityContainer {
    max-width: 100%;
    padding: 0 20px;
  }
}

.title {
  color: #fff;
  align-self: center;
  margin-top: 5px;
  font-weight: 600;
  font-size: 25px;
  margin-bottom: 20px;
}

.activityGridWrapper {
  height: 100%;
  overflow: auto;
}

.activityGrid {
  display: flex;
  flex-wrap: wrap;
  gap: 20px;
  justify-content: center;
  min-height: 85%;
  justify-content: center;
  align-items: center;
}

@media (max-width: 991px) {
  .activityGrid {
    max-width: 100%;
    flex-direction: column;
    align-items: stretch;
    gap: 0;
  }
}

.activityColumn {
  display: flex;
  flex-direction: column;
  line-height: normal;
  width: 28%;
  margin-left: 0;
}

@media (max-width: 991px) {
  .activityColumn {
    width: 100%;
  }
}

.activityCard {
  border-radius: 8px;
  border: 1px solid #383838;
  background-color: #000;
  display: flex;
  width: 100%;
  flex-direction: column;
  margin: 0 auto;
  padding: 16px 8px;
  padding-bottom: 0px;
  z-index: 2;
  cursor: pointer;
}

@media (max-width: 991px) {
  .activityCard {
    margin-top: 18px;
  }
}

.activityHeader {
  display: flex;
  padding-right: 6px;
  gap: 12px;
  font-size: 16px;
  color: #fff;
  font-weight: 600;
  justify-content: space-between;
}

.ellipsis {
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
  max-width: 75%;
}

.activityIcon {
  aspect-ratio: 1;
  object-fit: auto;
  object-position: center;
  width: 24px;
}

.activityImage {
  aspect-ratio: 2;
  object-fit: auto;
  object-position: center;
  margin-top: 16px;
  transition: transform 0.3s ease-in-out;
}

.activityTimestamp {
  color: #b6b6b6;
  font-weight: 500;
  font-size: 12px;
  margin: 0;
}

.cardTitle {
  color: #b6b6b6;
  font-weight: 500;
  font-size: 18px;
  margin: 0;
  max-width: 60%;
}

.cardBotttomSection {
  margin: 15px 3px 10px 3px;
  display: flex;
  justify-content: space-between;
  align-items: center;
}
.navigationButtons {
  align-self: flex-end;
  display: flex;
  margin-top: 15px;
  gap: 16px;
  font-size: 16px;
  color: #fff;
  font-weight: 600;
  white-space: nowrap;
  justify-content: end;
  margin-right: 40px;
}

@media (max-width: 991px) {
  .navigationButtons {
    white-space: initial;
  }
}

.previousButton {
  justify-content: center;
  border-radius: 5px;
  padding: 5px 20px;
  border: 1px solid #999999;
  background: transparent;
}

.disable {
  background-color: #999 !important;
  cursor: not-allowed !important;
}
@media (max-width: 991px) {
  .previousButton {
    white-space: initial;
  }
}

.nextButton {
  justify-content: center;
  border-radius: 5px;
  background-color: #ea3a1b;
  padding: 5px 20px;
  border: none;
}

@media (max-width: 991px) {
  .nextButton {
    white-space: initial;
  }
}
