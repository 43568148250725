/* sider styles  */
.detailsContainer {
  background-color: #2e2e2e;
  margin: 20px;
  border-radius: 5px;
}

.profileImageContainer {
  width: 16vw;
}
.profileImage {
  width: 16vw;
  /* height: 25%; */
  height: 250px;
  border-top-right-radius: 7px;
  border-top-left-radius: 7px;
}

.details {
  color: #fff;
  width: 100%;
  text-align: start;
  margin-top: 1vw;
  padding: 0 1vw;
}

.email {
  font-size: 0.9vw;
  white-space: nowrap;
  overflow: hidden;
  color: #939393;
}

.nameContainer {
  display: flex;
  align-items: center;
  justify-content: start;
  margin: 0.2vw 0;
}

.name {
  font-weight: bold;
  font-size: 1.1vw;
  white-space: nowrap;
  overflow: hidden;
}

.submenu {
  padding-left: 20px;
}

.logout {
  border: 1px solid #fc5252;
  display: flex;
  align-items: center;
  justify-content: center;
  color: #fc5252;
  cursor: pointer;
  width: 8vw;
  text-align: center;
  padding: 4px;
  border-radius: 5px;
  opacity: 0.8;
  position: absolute;
  bottom: 20px;
}

/* modal styles */
.modalContainer {
  display: flex;
  justify-content: center;
  align-items: center;
}

.modalFooterContainer {
  margin-top: 15px;
  display: flex;
  justify-content: center;
  align-items: center;
}

.footerBtn {
  margin-left: 10px;
}

.versionDescriber {
  margin-top: 25px;
  display: flex;
  justify-content: center;
  align-items: center;
  color: #6b6b6b;
  font-size: 15px;
  font-weight: bold;
  text-align: center;
}
.nameLetter {
  width: 100%;
  height: 200px;
  background-color: #fc5252;
  color: #fff;
  display: flex;
  align-items: center;
  justify-content: center;
  font-size: 40px;
  border-top-left-radius: 7px;
  border-top-right-radius: 7px;
}
.edit {
  position: absolute;
  right: 30px;
  top: 30px;
  width: 25px;
  height: 25px;
  text-align: center;
  border-radius: 100%;
  background-color: #fff;
  color: #fc5252;
  font-weight: bold;
  cursor: pointer;
}
.logoutYesBtn {
  border-radius: 4px !important;
  margin: 10px !important;
}
.logoutNoBtn {
  border-radius: 4px !important;
  margin: 10px !important;
  background-color: #ea3a1b !important;
}
.emoji {
  margin: 20px;
  display: flex;
  justify-content: center;
  align-items: center;
  font-size: 70px;
}
.yourGuest {
  display: flex;
  justify-content: center;
  align-items: center;
  height: 40%;
  color: #858585;
  font-size: 1.5vw;
  padding: 15px;
  text-align: center;
}
.loader {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  z-index: 10000;
  display: flex;
  justify-content: center;
  align-items: center;
  background-color: #131212;
  font-size: 30px;
  color: #939393;
}
