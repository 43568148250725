.container {
  text-align: center;
}
.container h2 {
  font-size: 2vw;
  font-weight: bold;
}

.imageContainer {
  height: 70vh;
  display: flex;
  align-items: center;
  justify-content: center;
}
.homeButton {
  color: #fff;
  background: #ea3a1b;
  border: none;
  border-radius: 1.5vw;
  padding: 0.5vw 2vw;
  font-size: 1vw;
  font-weight: 700;
  margin-top: 0.5vw;
}
