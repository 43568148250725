.logoContainer {
  display: flex;
  align-items: center;
  height: 4vw;
  width: 15vw;
}

.iconLogo {
  height: 100%;
  width: 100%;
}

@media (max-width: 480px) {
  .logoContainer {
    height: 20vw;
    width: 55vw;
  }
}
