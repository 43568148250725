.termsOfUseContainer {
  background-color: #f5f0f0;
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  color: #000;
}

.termsOfUseBody {
  width: 66%;
  background-color: #fdfcfa;
  padding: 90px 60px 0px 60px;
  text-align: justify;
  color: #000;
}
.termsOfUseBody h5 {
  color: #000;
}
.termsOfUseBody h6 {
  color: #000;
}

.primaryColor {
  color: #ea3a1b;
  text-align: center;
  font-size: 20px;
  padding: 2rem 0;
  font-weight: bold;
}

@media (max-width: 480px) {
  .termsOfUseBody {
    width: 100%;
    background-color: #fdfcfa;
    padding: 90px 60px 0px 60px;
    text-align: justify;
    color: #000;
  }
}
