.modalContainer {
  display: flex;
  justify-content: center;
  align-items: center;
}

.modalFooterContainer {
  margin-top: 15px;
  display: flex;
  justify-content: center;
  align-items: center;
}

.buttonContainer {
  width: 100%;
  display: flex;
  justify-content: space-between;
  font-size: 14px;
  font-weight: bold;
  padding: 0px 8px;
  border: none;
}

.noButton {
  background: #4f4f4f;
  font-size: 1vw;
  font-weight: bold;
  border: hidden;
  min-width: 100px;
  border-radius: 16px;
  padding: 0.3vw 1.2vw;
  height: auto;
  cursor: pointer;
}

.yesButton {
  background: #c65243;
  font-size: 1vw;
  font-weight: bold;
  border: none;
  border-radius: 16px;
  min-width: 100px;
  padding: 0.3vw 1.2vw;
  height: auto;
  margin-left: 20px;
  cursor: pointer;
}
