.topLayoutContainer {
  background: transparent !important;
  /* width: 100vw; occupy full screen */
  min-height: 100vh; /* occupy full screen */
}

.contentLayoutContainer {
  /* margin-top: 4vw; */
  /* max-height: calc(100vh - 4vw); left 4vh for header. */
  overflow-x: hidden;
  overflow-y: scroll;
}
