.header {
  position: fixed;
  z-index: 999;

  width: 100%;
  height: 4rem;
  padding: 0px;
}

.header .headerContainer {
  display: flex;
  align-items: center;
  justify-content: space-between;

  height: 100%;
  padding-left: 24px;
  padding-right: 24px;

  background-image: linear-gradient(180deg, rgba(0, 0, 0, 0.7) 10%, transparent);

  transition: background 0.5s;
}

/* Conditional class .headerDark */
.header .headerDark {
  background-color: #050505;
}

.header .headerContainerLeft {
  display: flex;
  justify-content: flex-start;
  align-items: center;
  gap: 24px;

  width: 50%;
  height: 100%;

  user-select: none;
}

.header .headerContainerRight {
  display: flex;
  justify-content: flex-end;
  align-items: center;
  gap: 24px;

  width: 50%;
  height: 100%;

  user-select: none;
}

.header .headerMenuItem {
  color: #fff;
  cursor: pointer;

  font-size: 16px;
  font-style: normal;
  font-weight: 400;
  line-height: normal;
  transition: color 0.4s;
}

.header .headerMenuItem:hover {
  color: #e5e5e5;
}

.header .logoContainer {
  display: flex;
  align-items: center;

  height: 100%;
  margin: 0 20px 0 0;
}

.header .headerLogo {
  height: 100%;
  padding: 15px 20px;
  cursor: pointer;
}

/* Header SignUp/Login Buttons */
.header .signUpBtn {
  padding: 0.3rem 1.3rem;
  border: none;
  border-radius: 4px;
  color: #fff;
  background: #ea3a1b;
  font-size: 1.1rem;
  font-weight: 400;
  transition: color 0.4s;
}

.header .loginBtn {
  border: none;
  color: #fff;
  font-size: 1vw;
  font-weight: bold;
  margin-right: 1rem;
  padding: 0.5rem 2rem;
  border-radius: 8px;
}

.header .signUpBtn:hover {
  color: #fff !important;
  text-decoration: none;
}

.header .loginBtn:hover {
  color: #ea3a1b !important;
  text-decoration: none;
}

/* Logout Modal styles */
.modalContainer {
  display: flex;
  justify-content: center;
  align-items: center;
}

.modalFooterContainer {
  margin-top: 15px;
  display: flex;
  justify-content: center;
  align-items: center;
}

.modalFooterBtn {
  margin-left: 10px;
}

.titleWidth {
  width: 180px;
  font-size: large;
}

.loginLeftContainer {
  height: 95vh;
  background-color: #24272e;
  display: flex;
  flex-direction: column;
  gap: 4rem;
  align-items: center;
  width: 50%;
  padding: 40px 20px 20px;
}
.loginLogo {
  /* height: 25rem; */
  width: 60%;
  margin-top: 20px;
}
.loginLeftContainerText {
  width: 25vw;
  font-size: 20px;
  font-weight: bold;
  text-align: center;
}
.loginRightContainer {
  height: 95vh;
  width: 50%;
  display: flex;
  align-items: center;
  flex-direction: column;
}
.modalContainer {
  display: flex;
  justify-content: space-between;
}
.privacyTermsContainer {
  display: flex;
  flex-direction: column;
}
.backbtnContainer {
  height: 5.5vh;
  position: sticky;
  top: 0;
  margin-left: 1rem;
  display: flex;
  font-size: 1.5rem;
  align-items: center;
}
.backbtn {
  cursor: pointer;
  font-size: 2rem;
}
.privacyTermsText {
  width: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
}
.privacyTermsBody {
  height: 89.5vh;
  overflow-y: scroll;
}

/* Media Queries */
@media (max-width: 480px) {
  .headerLogo {
    height: 80%;
    padding: 0.5vw;
    cursor: pointer;
  }

  .headerDrawerLogo {
    height: 10vw;
    margin-left: 12px;
  }

  .headerDrawerContainer {
    display: flex;
    align-items: center;
  }

  .closeIcon {
    font-size: 20px;
  }
}

@media (max-width: 900px) {
  .loginLogo {
    width: 20rem;
  }
}

/* Media Query for low resolution  Tablets, Ipads */
@media (min-width: 481px) and (max-width: 767px) {
  .headerDrawerLogo {
    height: 7vw;
    margin-left: 19px;
  }
}

/* Media Query for Tablets Ipads portrait mode */
@media (min-width: 768px) and (max-width: 1024px) {
  .headerDrawerLogo {
    height: 7vw;
    margin-left: 19px;
  }
}

.mobileHeaderContainer {
  display: flex;
  height: 100%;
  align-items: center;
  justify-content: space-between;
}

.mobileLogoConatiner {
  height: 100%;
  margin-left: 10px;
  display: flex;
  align-items: center;
}

.mobileHamburger {
  height: 20%;
  margin-right: 15px;
}

.mobileHeaderLogo {
  height: 100%;
  padding: 10px;
  cursor: pointer;
}
