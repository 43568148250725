.shareModalContainer {
  text-align: center;
}

.linkCointainer {
  display: flex;
  justify-content: space-evenly;
  align-items: center;
  min-height: 120px;
  background-color: #373737;
  border-radius: 10px;
  margin-top: 20px;
}

.icon {
  border-radius: 100%;
  width: 3vw;
  height: 3vw;
}
.twitterXIcon {
  width: 5.4vw;
  margin: 0px -17px;
}
.gmailIcon {
  width: 3vw;
  background-color: #fff;
  border-radius: 100%;
}
