.modalPlayListContainer {
  display: flex;
  justify-content: center;
  margin-top: 30px;
}
.watchlistButtonContainer {
  width: 100%;
  display: flex;
  justify-content: center;
}
.modalPlayList {
  width: 100%;
  display: flex;
  flex-direction: column;
  font-size: 12px;
  justify-content: space-evenly;
  align-items: center;
  letter-spacing: 0.8px;
}
.playlistText {
  margin-bottom: 20px;
  font-weight: bold;
  line-height: 21px;
  color: white;
}
.playlistInputContainer {
  background: #222126;
  width: 100%;
  border-radius: 5px;
}
.doneButton {
  background: #c65243;
  font-size: 15px;
  font-weight: bolder;
  width: 100%;
  border: hidden;
  border-radius: 15px;
  height: 32px;
  cursor: pointer;
}
.noDataAvailable {
  display: flex;
  justify-content: center;
  margin: 80px 0px 80px 0px;
}
.playlistCheckBoxContainer {
  background: #333333;
  width: 100%;
  padding: 5px 10px;
  border: 0px;
  border-radius: 5px;
  margin-bottom: 16px;
  font-size: 15px;
  font-weight: bold;
  display: flex;
}
.emptyTitle {
  color: red;
  align-self: start;
  padding: 0px 10px;
}
.privacyContainer {
  width: 100%;
  margin-bottom: 25px;
}
.privacyText {
  padding: 6px 0px;
  font-size: 15px;
  font-weight: bold;
  align-self: start;
  margin-top: 10px;
}
.privacyButtonContainer {
  width: 100%;
  display: flex;
  flex-direction: row;
  justify-content: space-between;
}
.publicButtonContainer {
  border: 0px;
  border-radius: 5px;
  padding: 6px 15px;
  cursor: pointer;
}
.publicText {
  display: flex;
  flex-direction: column;
}
.privateButtonContainer {
  border: 0px;
  border-radius: 5px;
  padding: 6px 15px;
  cursor: pointer;
}
.privateText {
  display: flex;
  flex-direction: column;
}
.cancel {
  background: #4f4f4f;
  font-size: 15px;
  font-weight: bold;
  border: hidden;
  border-radius: 16px;
  padding: 7px 60px;
  width: 180px;
  height: auto;
  cursor: pointer;
  white-space: nowrap;
}
.create {
  background: #c65243;
  font-size: 15px;
  font-weight: bold;
  border: hidden;
  border-radius: 16px;
  padding: 7px 60px;
  width: 180px;
  height: auto;
  cursor: pointer;
  white-space: nowrap;
}

.newPlaylistButton {
  color: #c65243;
  cursor: pointer;
  font-size: 13px;
  margin-left: 15px;
  margin-top: 5px;
}
.playlistContainer {
  max-height: 400px;
  overflow-y: scroll;
  width: 100%;
  margin-bottom: 10px;
}

.title {
  align-self: flex-start;
  font-size: 16px;
}

.privacyDivision {
  width: 48%;
  background-color: #333333;
  border-radius: 5px;
  display: flex;
  flex-direction: row;
  padding: 5px;
  cursor: pointer;
}

.radio {
  margin-top: 2px;
}

.privContainer {
  display: flex;
  flex-direction: column;
}

.privTitle {
  font-size: 14px;
}

.privDesc {
  font-size: 12px;
}

.addToContainer {
  display: flex;
  align-self: flex-start;
  margin-bottom: 12px;
}

.newPlaylistCont {
  display: flex;
  justify-content: space-between;
  width: 100%;
  align-items: center;
}

.newIcon {
  color: white;
  font-size: 18px;
}

.doneBtn {
  margin: 0px 10px 0px 10px;
}

.addToText {
  font-weight: 900;
  font-size: 22px;
  color: white;
  font-weight: bolder;
  font-family: notosans-bold;
}
