.loader {
  display: flex;
  min-height: inherit;
  height: 100%;
  width: 100%;
  justify-content: center;
  align-items: center;
  font-size: 1.5vw;
  font-weight: 700;
}
.spinner {
  animation: spin infinite 1s linear;
  margin-right: 5px;
}

@keyframes spin {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
}
