.tabContainer {
  display: flex;
  justify-content: center;
  align-items: center;
  margin: 1.5rem 0;
  font-size: 1.2vw;
  font-weight: bold;
  width: 25vw;
}

.menu {
  display: flex;
  justify-content: center;
  align-items: center;
  width: 50%;
  padding: 1rem 0;
  border-bottom: 2px solid #a6a6a6;
}

.tabLink {
  color: #a6a6a6;
  cursor: pointer;
}

.tabLink:hover {
  color: #ffffff;
}

.selected {
  border-bottom: 2px solid #ea3a1b;
  color: red;
}

.selected .tabLink {
  color: #ffffff;
}

@media (max-width: 480px) {
  .tabContainer {
    font-size: 3.5vw;
  }
}
