.formContainer {
  padding-top: 70px;
}

.form {
  display: flex;
  flex-direction: column;
  width: 25vw;
}

.tabContainer {
  display: flex;
  justify-content: center;
  align-items: center;
  margin: 1.5rem 0;
  font-size: 1.2vw;
  font-weight: bold;
}

.button {
  width: 12vw;
}

@media (max-width: 1000px) {
  .form {
    width: 35vw;
  }
}

@media (max-width: 480px) {
  .form {
    width: 80vw;
    /* background-color:aqua; */
  }
  .tabContainer {
    font-size: 3vw;
  }
  .guestUserNotifierText {
    font-size: 3vw;
  }
}

.resendOTPWrapper {
  color: #ffffff;
  font-size: 1vw;
  padding: 0 0.5rem;
  display: flex;
  justify-content: flex-end;
  transition: color 0.3s;
}

.resendOTP:hover {
  color: #ea3a1b;
  cursor: pointer;
}

.disabled {
  cursor: none;
  pointer-events: none;
  color: darkgray;
}
